import React from 'react'
import './LocationsHome.scss'
// ESTÚDIO 1
import image1 from '../../../assets/images/home/location/IMAGE-1.jpg'
import image2 from '../../../assets/images/home/location/IMAGE-2.jpg'
import image3 from '../../../assets/images/home/location/IMAGE-3.jpg'
import image4 from '../../../assets/images/home/location/IMAGE-4.jpg'
import image5 from '../../../assets/images/home/location/IMAGE-5.jpg'
import image6 from '../../../assets/images/home/location/IMAGE-6.jpg'
import image7 from '../../../assets/images/home/location/IMAGE-7.jpg'
import image8 from '../../../assets/images/home/location/IMAGE-8.jpg'
import image9 from '../../../assets/images/home/location/IMAGE-9.jpg'
import image10 from '../../../assets/images/home/location/IMAGE-10.jpg'
import image11 from '../../../assets/images/home/location/IMAGE-11.jpg'
import image12 from '../../../assets/images/home/location/IMAGE-12.jpg'
import image13 from '../../../assets/images/home/location/IMAGE-13.jpg'
import image14 from '../../../assets/images/home/location/IMAGE-14.jpg'
import image15 from '../../../assets/images/home/location/IMAGE-15.jpg'
import image16 from '../../../assets/images/home/location/IMAGE-16.jpg'

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LocationsHome = () => {
  const images = [
    { src: image1, alt: "Imagem 1" },
    { src: image2, alt: "Imagem 2" },
    { src: image3, alt: "Imagem 3" },
    { src: image4, alt: "Imagem 4" },
    { src: image5, alt: "Imagem 5" },
    { src: image6, alt: "Imagem 6" },
    { src: image7, alt: "Imagem 7" },
    { src: image8, alt: "Imagem 8" },
    { src: image9, alt: "Imagem 9" },
    { src: image10, alt: "Imagem 10" },
    { src: image11, alt: "Imagem 11" },
    { src: image12, alt: "Imagem 12" },
    { src: image13, alt: "Imagem 13" },
    { src: image14, alt: "Imagem 14" },
    { src: image15, alt: "Imagem 15" },
    { src: image16, alt: "Imagem 16" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='LocationsHome'>
      <div className="container-locations-title">
        <div className="title">
          <h1>Espaço Midas</h1>
          <span>LOCAÇÃO</span>
        </div>
        <p>O espaço Midas é ideal para a sua sessão de fotos, produção de conteúdos audiovisuais em geral como videoclipes, podcasts, comerciais e lives, além de oferecer uma excelente estrutura para reuniões, coworking e sala de edição.</p>
      </div>

      <div className="carousel-container">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="client-slider item">
              <div className="image-list">
                <div className="content-image">
                  <img loading="lazy" decoding="async" src={image.src} className="main-image" alt={image.alt} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <a href="mailto:locacao@midasmusic.com.br" target="_blank" className="btn-artists" role="button" rel="noreferrer">
        <span class="elementor-button-content-wrapper">
          <span class="elementor-button-text">AGENDE</span>
        </span>
      </a>
    </div>
  )
}

export default LocationsHome
