import React, { useState } from 'react';
import './Navbar.scss';
import logo from '../../../assets/images/navbar/logotipo.png';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  console.log(location.pathname);

  const getLinkClass = (path) => {
    return location.pathname === path ? 'navbar-item active' : 'navbar-item';
  };

  return (
    <nav className="Navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/">
            <img loading="lazy" src={logo} alt="MidasMusic" />
          </Link>
        </div>
        <div className={`navbar-menu ${menuOpen ? 'open' : ''}`}>
          <div className="navbar-open">
            <div className="navbar-logo">
              <Link to="/">
                <img loading="lazy" src={logo} alt="MidasMusic" />
              </Link>
            </div>
            <button className='close' onClick={() => toggleMenu()}><span class="majesticons--close-line" /></button>

          </div>
          <ul className="navbar-list">
            <li className={getLinkClass('/')}>
              <Link onClick={() => setMenuOpen(false)} to="/">Home</Link>
            </li>
            <li className={getLinkClass('/equipe')}>
              <Link onClick={() => setMenuOpen(false)} to="/equipe">Midas Music</Link>
            </li>
            <li className={getLinkClass('/estudios')}>
              <Link onClick={() => setMenuOpen(false)} to="/estudios">Estúdios</Link>
            </li>
            <li className={getLinkClass('/artistas')}>
              <Link onClick={() => setMenuOpen(false)} to="/artistas">Artistas</Link>
            </li>
            <li className={getLinkClass('/servicos')}>
              <Link onClick={() => setMenuOpen(false)} to="/servicos">Serviços</Link>
            </li>
            <li className={getLinkClass('/comunidade')}>
              <Link onClick={() => setMenuOpen(false)} to="/comunidade">Comunidade</Link>
            </li>
            <li className={getLinkClass('/contato')}>
              <Link onClick={() => setMenuOpen(false)} to="/contato">Contato</Link>
            </li>
          </ul>
        </div>
        <div className="navbar-desktop">
          <div className="navbar-button">
            <a
              href="https://api.whatsapp.com/send?phone=5511994405225&amp;text=Mande%20sua%20mensagem."
              target="_blank"
              rel="noreferrer"
            >
              <span className="ph--phone-fill" />
              <span>Whatsapp</span>
            </a>
          </div>
        </div>
        <div className="navbar-mobile">
          <button className="hamburger" onClick={toggleMenu}>
            &#9776;
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
