import React from 'react'
import './Contact.scss'
import bg from '../../../assets/images/partners/studio/IMAGE-6.webp'

const Contact = () => {
  return (
    <div className='Contact'>
      <div className="background-contact" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container-title">
          <div className="title">
            <h1>Contato</h1>
            <span>Contato</span>
          </div>
          <p>Entre em contato com o Midas Music.</p>
        </div>
      </div>
      <div className="container-contact">
        <div className="box-contact">
          <div className="info-maps">
            <div className="box-map">
              <h1>Entre em contato</h1>
              <iframe
                className="responsive-iframe"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14635.231052096091!2d-46.6443491!3d-23.5034327!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef7c4d0ed42f5%3A0x765459f7a3e4bc0c!2sMidas%20Music!5e0!3m2!1sen!2sbr!4v1718391486462!5m2!1sen!2sbr"
                allowFullScreen=""
                title="Mapa do Midas Music"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
              <div className='info-map'>
                <p>Midas Music</p>
                <p>São Paulo - SP</p>
              </div>
            </div>

            <div className="container-infos-click">
              <ul className="contact-list">
                <li className="contact-list-item">
                  <span class="fa--phone" />
                  <span className="contact-text">+55 11 2256 – 5555</span>
                </li>
                <li className="contact-list-item">
                  <span className="fa--envelope-o" />
                  <span className="contact-text">contato@midasmusic.com.br</span>
                </li>
                <li className="contact-list-item">
                  <a href="https://web.archive.org/web/20240227200347/https://goo.gl/maps/a3NFXDYuVwGbJLx49">
                    <span class="ph--map-pin-fill"/>
                    <span className="contact-text">
                      R. Leão XIII, 116 - Jd. São Bento -  <br/> São Paulo - SP - 02526-000
                    </span>
                  </a>
                </li>
              </ul>
              <ul className="contact-list">
                <li className="contact-list-item">
                <span class="ic--baseline-whatsapp"/>
                  <span className="contact-text">+55 11 99440-5225</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
