import React from 'react'
import './HomeMarket.scss'
import microphone from '../../../assets/images/home/market/microphone.png'
import djdeck from '../../../assets/images/home/market/dj_deck.png'
import audiointerface from '../../../assets/images/home/market/audio_interface.png'
import drummachine from '../../../assets/images/home/market/drum_machine.png'
import synthesizer from '../../../assets/images/home/market/synthesizer.png'
import djmixer from '../../../assets/images/home/market/dj_mixer.png'

const HomeMarket = () => {
  return (
    <section className='HomeMarket'>
      <h2>Mais de 30 anos de expertise no mercado fonográfico</h2>
      <div className="container-markets">
        <div className="container-line">
          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={microphone} alt="Microfone" />
            </div>
            <div className="market-body">
              <h3>PRODUÇÃO MUSICAL</h3>
              <p>Produza suas músicas com a equipe vencedora de vários prêmios Grammy’s, VMB’s e recordista de vendagens. <br />
                O estúdio Midas oferece padrão internacional em produção.</p>
            </div>
          </div>

          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={djdeck} alt="mixagem" />
            </div>
            <div className="market-body">
              <h3>MIXAGEM</h3>
              <p>Nossos estúdios estão equipados com os lendários consoles solid state logic e conversores de alta resolução, além de outboards analógicos e plugins para a sua mixagem. O estúdio 3 está certificado pela dolby para mixagens em 7.1.4 dolby atmos.</p>
            </div>
          </div>

          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={audiointerface} alt="MASTERTIZAÇÃO" />
            </div>
            <div className="market-body">
              <h3>MASTERTIZAÇÃO</h3>
              <p>A experiência dos nossos engenheiros e os equipamentos analógicos e digitais vintage ou de última geração estão à sua disposição para finalizar a sua música com o punch e a clareza conhecidos e ouvidos em muitos sucessos que fazem parte da história da música brasileira por várias gerações.</p>
            </div>
          </div>
        </div>
        <div className="container-line">
          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={drummachine} alt="GERENCIAMENTO ARTÍSTICO" />
            </div>
            <div className="market-body">
              <h3>GERENCIAMENTO ARTÍSTICO</h3>
              <p>Assessoria e acompanhamento da equipe Midas Music para ajudar você a dar os próximos passos da sua carreira.</p>
            </div>
          </div>

          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={synthesizer} alt="WORKSHOPS" />
            </div>
            <div className="market-body">
              <h3>WORKSHOPS</h3>
              <p>Participe dos nossos workshops com nossa equipe. Fique ligado na agenda, teremos workshops de carreira, marketing, composição e gravação.</p>
            </div>
          </div>

          <div className="market">
            <div className="header">
              <img loading="lazy" decoding='async' src={djmixer} alt="JINGLES" />
            </div>
            <div className="market-body">
              <h3>JINGLES</h3>
              <p>Cative o seu público com uma mensagem direta da sua marca ou empresa.</p>
            </div>
          </div>
        </div>

        <div className="button-market">
          <a href="https://api.whatsapp.com/send?phone=5511994405225&amp;text=Mande%20sua%20mensagem." target="_blank" role="button" rel="noreferrer">
            <span>
              <span>SERVIÇOS</span>
            </span>
          </a>
        </div>
      </div>
    </section>
  )
}

export default HomeMarket
