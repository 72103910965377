import React, { useState } from 'react';
import './DetailsSummary.scss';

const DetailsSummary = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    return (
        <details className={`details-summary ${isOpen ? 'open' : ''}`} open={isOpen}>
            <summary onClick={toggleOpen} className={`summary ${isOpen ? 'open' : ''}`}>
                <span>{title}</span>
                <span className={`arrow ${isOpen ? 'up' : 'down'}`}></span>
            </summary>
            <div className="content">
                {children}
            </div>
        </details>
    );
};

export default DetailsSummary;
