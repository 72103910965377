import React from 'react'
import './Artists.scss'
import bg from '../../../assets/images/artists/bg-section.jpg'
import ArtistCard from '../../common/ArtistCard/ArtistCard'

// ARTISTAS
import vitor from '../../../assets/images/artists/vitor.jpg'
import titas from '../../../assets/images/artists/titas.jpg'
import zimbra from '../../../assets/images/artists/zimbra.jpg'
import rick from '../../../assets/images/artists/rick.jpg'
import daza from '../../../assets/images/artists/daza.jpg'
import morgana from '../../../assets/images/artists/morgana.jpg'
import sergio from '../../../assets/images/artists/sergio.jpg'
import suhai from '../../../assets/images/artists/suhai.jpg'
import bruna from '../../../assets/images/artists/bruna.jpg'
import theo from '../../../assets/images/artists/theo.jpg'
import lohr from '../../../assets/images/artists/lohr.jpg'
import dennix from '../../../assets/images/artists/dennix.jpg'

// DISCOS
import disco1 from '../../../assets/images/artists/discos/charlie.jpeg'
import disco2 from '../../../assets/images/artists/discos/vitor.jpeg'
import disco3 from '../../../assets/images/artists/discos/manu.jpeg'
import disco4 from '../../../assets/images/artists/discos/vicka.jpeg'
import disco5 from '../../../assets/images/artists/discos/mamonas.jpeg'
import disco6 from '../../../assets/images/artists/discos/kell.jpeg'
import disco7 from '../../../assets/images/artists/discos/cpm.jpeg'
import disco8 from '../../../assets/images/artists/discos/amado.jpeg'
import disco9 from '../../../assets/images/artists/discos/nxzero.jpeg'
import disco10 from '../../../assets/images/artists/discos/titas.jpeg'
import disco11 from '../../../assets/images/artists/discos/ira.jpeg'
import disco12 from '../../../assets/images/artists/discos/rouge.jpeg'

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';

const Artists = () => {
  const artists = [
    {
      name: 'Titãs',
      backgroundImage: titas,
      socialLinks: {
        facebook: 'https://www.facebook.com/titasoficial',
        twitter: 'https://twitter.com/titasoficial',
        instagram: 'https://www.instagram.com/titasoficial/?hl=en',
      },
    },
    {
      name: 'Vitor Kley',
      backgroundImage: vitor,
      socialLinks: {
        facebook: 'https://www.facebook.com/vitorkleyoficial',
        twitter: 'https://twitter.com/VitorKley',
        instagram: 'https://www.instagram.com/vitorkley/',
      },
    },
    {
      name: 'Zimbra',
      backgroundImage: zimbra,
      socialLinks: {
        facebook: 'https://www.facebook.com/bandazimbra',
        twitter: 'https://twitter.com/zimbraoficial',
        instagram: 'https://www.instagram.com/bandazimbra/',
      },
    },
    {
      name: 'Rick Bonadio',
      backgroundImage: rick,
      socialLinks: {
        facebook: 'https://www.facebook.com/rick.bonadio.9',
        instagram: 'https://www.instagram.com/rickbonadio/',
      },
    },
    {
      name: 'Dazaranha',
      backgroundImage: daza,
      socialLinks: {
        facebook: 'https://www.facebook.com/oficialDazaranha',
        instagram: 'https://www.instagram.com/dazaranha/',
        tiktok: 'https://www.tiktok.com/@dazaranha',
      },
    },
    {
      name: 'Morgana Ferrer',
      backgroundImage: morgana,
      socialLinks: {
        facebook: 'https://www.facebook.com/djmorganaferrer',
        instagram: 'https://www.instagram.com/djmorganaferrer/',
      },
    },
    {
      name: 'Sérgio Britto',
      backgroundImage: sergio,
      socialLinks: {
        facebook: 'https://www.facebook.com/profile.php?id=100044141061130',
        instagram: 'https://www.instagram.com/sergiobritto_oficial/',
      },
    },
    {
      name: 'Suhai',
      backgroundImage: suhai,
      socialLinks: {
        facebook: 'https://www.facebook.com/SUHAImusic',
        instagram: 'https://www.instagram.com/suhaimusic/',
      },
    },
    {
      name: 'Bruna Magalhães',
      backgroundImage: bruna,
      socialLinks: {
        facebook: 'https://www.facebook.com/bruna.magalhaes.3954',
        instagram: 'https://www.instagram.com/brunamagalhaesmusica/',
      },
    },
    {
      name: 'Théo Medom',
      backgroundImage: theo,
      socialLinks: {
        facebook: 'https://www.facebook.com/theomedonoficial',
        instagram: 'https://www.instagram.com/theomedon/',
      },
    },
    {
      name: 'Lohr',
      backgroundImage: lohr,
      socialLinks: {
        instagram: 'https://www.instagram.com/djlohr/?fbclid=IwAR1f3r6C0L9LoXqKtzH1CZFK4_aesEomCkq2-h5EarTUJdqAvh7Db-ILdy4',
      },
    },
    {
      name: 'Dennix',
      backgroundImage: dennix,
      socialLinks: {
        instagram: 'https://www.instagram.com/dennixoficial/',
      },
    },
  ];

  const images = [
    { src: disco1, alt: "Charlie Brown Jr." },
    { src: disco2, alt: "Vitor Kley" },
    { src: disco3, alt: "Manu Gavassi" },
    { src: disco4, alt: "Vicka" },
    { src: disco5, alt: "Mamonas Assassinas" },
    { src: disco6, alt: "Kell Smith" },
    { src: disco7, alt: "CPM 22" },
    { src: disco8, alt: "Amado Batista" },
    { src: disco9, alt: "NX Zero" },
    { src: disco10, alt: "Titãs" },
    { src: disco11, alt: "Ira!" },
    { src: disco12, alt: "Rouge" },
  ];

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 300,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const counters = [
    {
      id: 1,
      to: 2,
      title: "+2 Bilhõe de <br/> Visualizações no <br/> Youtube",
    },
    {
      id: 2,
      to: 2,
      title: "+2 Bilhões de <br/> Streaming nos players <br/> de música",
    },
    {
      id: 3,
      to: 1000,
      suffix: "",
      title: "+1000 artistas <br/> produzidos",
    },
    {
      id: 4,
      to: 12,
      suffix: "",
      title: "Premiações Grammy",
    },
  ];

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });


  return (
    <section className='Artists'>
      <div className="background-artists" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container-title">
          <div className="title">
            <h1>Artistas</h1>
            <span>MIDAS MUSIC</span>
          </div>
          <p>Nossos artistas criam música de qualidade. EPs e singles são distribuídos <br /> em todo o mundo.</p>
        </div>
      </div>
      <div className="container-artists">
        <div className="box-artists">
          {artists.map((artist) => (
            <ArtistCard name={artist.name} backgroundImage={artist.backgroundImage} socialLinks={artist.socialLinks} />
          ))}
        </div>
      </div>

      <div className="container-videos-artists">
        <div className="container-videos-title">
          <div className="title">
            <h1>VIDEOCLIPES</h1>
            <span>DESTAQUES</span>
          </div>
          <p>Confira os lançamentos Midas Music</p>
        </div>
        <div className="box-videos">
          <div className="box">
            <iframe
              src="https://www.youtube.com/embed/8xvZtgmWQLE?si=nvRIOILLGqBUAKZO"
              title="Vitor Kley - O Que É Uma Música?"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen>
            </iframe>
          </div>
          <div className="box">
            <iframe
              src="https://www.youtube.com/embed/-n5Giffh3Fs?si=5Y0H6oVMUh2lmBKc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>

      <div className="container-productions">
        <p className='title'>Produções Midas</p>
        <div className="carousel-container">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="client-slider item">
                <div className="image-list">
                  <div className="content-image">
                    <img loading="lazy" decoding="async" src={image.src} className="main-image" alt={image.alt} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="container-counts">
        <div className="counter-container" ref={ref}>
          {counters.map((counter) => (
            <div key={counter.id} className="counter-column">
              <div className="counter-widget">
                <div className="counter-number-wrapper">
                  <span className="counter-prefix"></span>
                  {inView && (
                    <CountUp
                      start={0}
                      end={counter.to}
                      duration={3}
                      separator="."
                      suffix={counter.suffix}
                    />
                  )}
                  <span className="counter-suffix"></span>
                </div>
                <div
                  className="counter-title"
                  dangerouslySetInnerHTML={{ __html: counter.title }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Artists
